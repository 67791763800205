import React, {Component} from 'react';
import Logo from "./logo";
import PropTypes from "prop-types";
import {loadHandler} from "../tools/loader";

class Header extends Component {
    constructor() {
        super();
        this.home = this.home.bind(this);
    }

    render() {
        return(
            <div className={
                "header " + (this.props.absolute ? "absolute" : "") + (this.props.fullWidth ? " full-width" : "")
            }>
                <div onClick={this.home}>
                    <Logo color={this.props.invert ? loadHandler.invertFill : '#fff'}
                          width="5rem" className="mini-logo"/>
                </div>
                {this.props.children}
            </div>
        )
    }

    home() {
        let params = new URLSearchParams();
        params.set(this.props.params[0], this.props.params[1]);
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + params.toString();
        window.history.pushState({path:newUrl},'',newUrl);
        this.props.onPageChange(this.props.page);
    }
}

Header.propTypes = {
    onPageChange: PropTypes.func,
    params: PropTypes.array,
    page: PropTypes.string,
    absolute: PropTypes.bool,
    invert: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

export default Header;