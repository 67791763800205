import React, {Component} from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import {win} from './utils';

class UIRotator extends  Component {
    constructor(props) {
        super(props);

        this.animate = this.animate.bind(this);
        this.setItems = this.setItems.bind(this);
        this.setText = this.setText.bind(this);
        this.getIndex = this.getIndex.bind(this);
        this.resetAnim = this.resetAnim.bind(this);

        this.looper = this.looper.bind(this);
        this.createLoop = this.createLoop.bind(this);
        this.stopLoop = this.stopLoop.bind(this);


        this.rotator = null;
        this.top2 = null;
        this.top = null;
        this.sel = null;
        this.btm = null;
        this.btm2 = null;

        this.selected = 0;
        this.init = false;
        this.dir = 1;
        this.delay = 1000;
        this.timer = null;

        this.items = [];

        this.fs = {
            sel: "4vh",
            norm: "2vh"
        }

        if (win.orientation === win.PORTRAIT) {
            this.fs.sel = "5vw";
            this.fs.norm = "4vw";
        }
    }

    render() {
        return(
            <div className="ui-rotator" ref={el => {this.rotator = el}}>
                <div ref={el => this.top2 = el}>1</div>
                <div ref={el => this.top = el}>2</div>
                <div ref={el => this.sel = el}>3</div>
                <div ref={el => this.btm = el}>4</div>
                <div ref={el => this.btm2 = el}>5</div>
            </div>
        )
    }

    animate(direction) {
        let sel = this.getIndex(this.selected, direction);

        if (this.sel !== null) {
            if (!this.init) {
                anime({
                    targets: this.sel,
                    fontSize: [this.fs.norm, this.fs.sel],
                    color: this.props.fill,
                    duration: this.props.duration,
                    easing: 'cubicBezier(0.7,0,0.3,1)',
                });
                this.init = true;
            } else {

                if (direction === -1) {
                    anime.timeline().add({
                        targets: this.sel,
                        translateY: this.top2.offsetHeight,
                        fontSize: [this.fs.sel, this.fs.norm],
                        color: '#8d8d8d',
                        opacity: 1,
                        duration: this.props.duration,
                        easing: 'cubicBezier(0.7,0,0.3,1)',
                    }).add({
                        targets: this.btm,
                        fontSize: [this.fs.norm, this.fs.norm],
                        translateY: this.top2.offsetHeight,
                        opacity: 0,
                        duration: this.props.duration,
                        easing: 'cubicBezier(0.7,0,0.3,1)',
                    }, 0).add({
                        targets: this.top2,
                        fontSize: [this.fs.norm, this.fs.norm],
                        translateY: this.top2.offsetHeight,
                        opacity: 1,
                        duration: this.props.duration,
                        easing: 'cubicBezier(0.7,0,0.3,1)',
                    }, 0).add({
                        targets: this.top,
                        translateY: this.top2.offsetHeight,
                        fontSize: [this.fs.norm, this.fs.sel],
                        color: this.props.fill,
                        opacity: 1,
                        duration: this.props.duration,
                        easing: 'cubicBezier(0.7,0,0.3,1)',
                        complete: () => {
                            this.setText(sel)
                        }
                    }, 0);
                } else {
                    anime.timeline().add({
                        targets: this.sel,
                        translateY: -this.top2.offsetHeight,
                        fontSize: [this.fs.sel, this.fs.norm],
                        color: '#8d8d8d',
                        opacity: 1,
                        duration: this.props.duration,
                        easing: 'cubicBezier(0.7,0,0.3,1)',
                    }).add({
                        targets: this.top,
                        translateY: -this.top2.offsetHeight,
                        fontSize: [this.fs.norm, this.fs.norm],
                        opacity: 0,
                        duration: this.props.duration,
                        easing: 'cubicBezier(0.7,0,0.3,1)',
                    }, 0).add({
                        targets: this.btm2,
                        translateY: -this.top2.offsetHeight,
                        fontSize: [this.fs.norm, this.fs.norm],
                        opacity: 1,
                        duration: this.props.duration,
                        easing: 'cubicBezier(0.7,0,0.3,1)',
                    }, 0).add({
                        targets: this.btm,
                        translateY: -this.top2.offsetHeight,
                        fontSize: [this.fs.norm, this.fs.sel],
                        color: this.props.fill,
                        opacity: 1,
                        duration: this.props.duration,
                        easing: 'cubicBezier(0.7,0,0.3,1)',
                        complete: () => {
                            this.setText(sel)
                        }
                    }, 0);
                }
            }
        }
    }

    setText(index) {
        try {
            this.top2.innerText = this.items[this.getIndex(index, -2)];
            this.top.innerText = this.items[this.getIndex(index, -1)];
            this.sel.innerText = this.items[index];
            this.btm.innerText = this.items[this.getIndex(index, 1)];
            this.btm2.innerText = this.items[this.getIndex(index, 2)];
        } catch (e) {
            console.log(e)
        }
        this.resetAnim();

        this.selected = index;
    }

    setItems(items) {
        this.items = items;

        this.setText(0);
    }

    resetAnim() {
        if (this.sel !== null) {
            anime.timeline().add({
                targets: this.sel,
                translateY: 0,
                fontSize: this.fs.sel,
                opacity: 1,
                color: this.props.fill,
                duration: 0,
            }).add({
                targets: [this.top, this.btm],
                translateY: 0,
                fontSize: this.fs.norm,
                color: '#8d8d8d',
                opacity: 1,
                duration: 0,
            }, 0).add({
                targets: [this.btm2, this.top2],
                fontSize: this.fs.norm,
                translateY: 0,
                opacity: 0,
                duration: 0,
            }, 0);
        }
    }

    getIndex(ref, diff) {
        let sel = (ref + diff) % this.items.length;
        if (sel < 0) {
            sel = this.items.length + sel;
        }
        return sel
    }

    createLoop(dir, delay) {
        this.dir = dir;
        this.delay = delay;
        this.looper();
    }

    looper() {
        this.animate(this.dir);
        this.timer = setTimeout(this.looper, this.delay)
    }

    stopLoop() {
        clearTimeout(this.timer);
    }

    componentDidMount() {


    }
}


UIRotator.propTypes = {
    items: PropTypes.array,
    fill: PropTypes.string,
    direction: PropTypes.number,
    duration: PropTypes.number,
};

export default UIRotator;