import React from 'react';
import db from '../database.js';
import Page from './page.js';
import PropTypes from "prop-types";
import Header from "../tools/header";
import {loadHandler} from "../tools/loader";

class View extends Page {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.title = null;
        this.inner = null;
        this.custom = null;
    }

    render() {
        return(
            <section className="admin container-wrapper">
                <Header onPageChange={this.props.onPageChange}
                        params={["", ""]}
                        fullWidth={true}
                        page="main"/>

                <div className="container-none">
                    <div className="box">
                        <div className="absolute-box">
                            <div className="title" ref={el => {this.title = el}}>

                            </div>
                            {/*<div className="custom-content" ref={el => {this.custom = el}}>*/}

                            {/*</div>*/}

                        </div>
                        <section className="view-box">
                            <section className="inner-view" ref={el => {this.inner = el}}>

                            </section>
                        </section>
                    </div>
                </div>
            </section>
        )
    }

    componentDidMount() {
        super.componentDidMount();

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let view = params.get('v');

        if (view != null && view !== undefined) {
            db.onReady(() => {
                let post = db.postDB[view];
                console.log(post);
                if (post != null && post !== undefined) {
                    // let arr = post.title.split(" ");
                    // let ttl = '';
                    // arr.forEach(subttl=>{
                    //     ttl += '<span style="color: ' + loadHandler.invertFill + '">' + subttl.substring(0, 1) + '</span>' + subttl.substring(1) + ' ';
                    // });
                    // console.log(arr);
                    // this.title.innerHTML = '<span style="color: ' + loadHandler.invertFill + '">' + post.title.substring(0, 1) + '</span>' + post.title.substring(1);
                    // this.title.innerHTML =  '<span style="color: ' + loadHandler.invertFill + '">' + post.title + '</span>';
                    this.title.innerHTML =  post.title ;
                    if (post.featimg !== '') {
                        // let uuid = loadHandler.registerLoad();
                        // this.custom.innerHTML = '<img src="' + post.featimg + '"/>';
                        // this.custom.querySelector('img').onload = () => {
                        //     loadHandler.loaded(uuid);
                        // };
                    }
                    this.inner.innerHTML = post.desc;
                    this.inner.querySelectorAll('img').forEach(el=> {
                        let uuid = loadHandler.registerLoad();
                        el.onload = () => {
                            loadHandler.loaded(uuid);
                        }
                    })
                }
            })
        }

    }
}

View.propTypes = {
    onPageChange: PropTypes.func
};

export default View;