import React from 'react';
import db from '../database.js';
import {win} from '../tools/utils';
import Page from './page.js';
import PropTypes from "prop-types";
import Header from "../tools/header";
import {loadHandler} from "../tools/loader";
import UIRotator from "../tools/ui-rotator";

class MainPage extends Page {
    constructor(props) {
        super(props);
        this.state = {
            orientation: win.orientation,
        };

        this.orientationChanged = this.orientationChanged.bind(this);
        this.animateIntro = this.animateIntro.bind(this);

        this.mounted = false;
        win.setOrientationListener(this.orientationChanged);

        loadHandler.loadComplete = this.animateIntro;

        this.container = null;
        this.all = null;
        this.rotator = null;

        this.els = {};

        this.featured = {
            el: null,
            update: () => {
                let posts = db.getFeaturedPosts();
                let contents = this.featured.el.querySelector('.contents');
                contents.innerHTML = '';
                Object.keys(posts).forEach(key=>{
                    let item = document.createElement('div');
                    item.className = 'item';
                    item.innerHTML = '<div class="wrapper">' +
                        '<img src="' + posts[key].featimg + '"/>' +
                        '<div class="name">' +
                        posts[key].title +
                        '</div>' +
                        '</div>';
                    item.id = key;
                    item.setAttribute('order', posts[key].order);
                    item.onclick = () => {
                        this.changePage(['v', key], 'view');
                    };

                    contents.appendChild(item);
                })
            },
        };

        this.custom = {
            el: null,
            update: () => {
                let sections = db.getVisibleCustomContent();
                Object.keys(sections).forEach(key=>{
                    let item = document.createElement('div');
                    item.className = 'custom-item';
                    item.id = key;
                    item.innerHTML = sections[key].desc;
                    this.custom.el.appendChild(item);
                })
            }
        };

        this.all = {
            el: null,
            update: () => {
                let posts = db.getNonFeaturedPosts();
                let contents = this.all.el.querySelector('.contents');
                contents.innerHTML = '';
                Object.keys(posts).forEach(key=>{
                    let item = document.createElement('div');
                    item.className = 'item';
                    item.innerHTML = '<div class="wrapper">' +
                        '<img src="' + posts[key].featimg + '"/>' +
                        '<div class="name">' +
                        posts[key].title +
                        '</div>' +
                        '</div>';
                    item.id = key;
                    item.setAttribute('order', posts[key].order);
                    item.onclick = () => {
                        this.changePage(['v', key], 'view');
                    };

                    contents.appendChild(item);
                })
            },
        }
    }

    render() {
        return(
            <section className={"main container-wrapper " + (win.orientation === win.LANDSCAPE ? "": "portrait")}>
                <Header onPageChange={this.props.onPageChange}
                        params={['', '']} page={'main'} absolute={true}/>
                <div className="container-none" ref={el => this.container = el}>
                    <section className="intro">
                        <div className="card">
                            <div className="greeting" onClick={() => {this.rotator.animate(-1)}}>
                                <div >
                                    HE
                                </div>
                                <div>
                                    LLO<span style={{"color": loadHandler.invertFill}}>.</span>
                                </div>
                            </div>
                            <div className="ama">
                                <div className="i-ama" onClick={() => {this.rotator.animate(1)}}>
                                    <span><span style={{"color": loadHandler.invertFill}}>I</span> am a</span>
                                </div>
                                <div className="occupation">
                                    <UIRotator ref={el=>{this.rotator = el}}
                                               duration={300}
                                               fill={loadHandler.fill}
                                               direction={1}/>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/in/karunikachoo/" title="Linked-In">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-linkedin">
                                            <path
                                                d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/>
                                            <rect x="2" y="9" width="4" height="12"/>
                                            <circle cx="4" cy="4" r="2"/>
                                        </svg>
                                    </a>
                                    <a href="mailto:karunikachoo@gmail.com" title="Send me an E-mail.">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-mail">
                                            <path
                                                d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                                            <polyline points="22,6 12,13 2,6"/>
                                        </svg>
                                    </a>
                                    <a href="https://firebasestorage.googleapis.com/v0/b/portfolio-8c0bd.appspot.com/o/CV%2FWEBSITE_KARUNIKA_CHOO_JIA_YE_31_MAY_2019.pdf?alt=media&token=2727f9b9-21d0-4adb-a44a-bde7806c4c34" title="Get a copy of my CV.">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-file-text">
                                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                                            <polyline points="14 2 14 8 20 8"/>
                                            <line x1="16" y1="13" x2="8" y2="13"/>
                                            <line x1="16" y1="17" x2="8" y2="17"/>
                                            <polyline points="10 9 9 9 8 9"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="featured" ref={el=>{this.featured.el = el}}>
                        <button className="next-btn heartbeat" onClick={() => {
                            this.featured.el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                        }}>
                            Featured Work
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-chevron-down">
                                <polyline points="6 9 12 15 18 9"/>
                            </svg>
                        </button>
                        <div className="contents" >

                        </div>
                        {/*<button className="next-btn" onClick={() => {*/}
                            {/*this.aboutMe.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});*/}
                        {/*}}>*/}
                            {/*About Me*/}
                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"*/}
                                 {/*fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"*/}
                                 {/*strokeLinejoin="round" className="feather feather-chevron-down">*/}
                                {/*<polyline points="6 9 12 15 18 9"/>*/}
                            {/*</svg>*/}
                        {/*</button>*/}
                    </section>
                    <section className="featured" ref={el=>this.els["skills"] = el}>
                        <button className="next-btn" onClick={() => {
                            this.els["skills"].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                        }}>
                            Skills
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-chevron-down">
                                <polyline points="6 9 12 15 18 9"/>
                            </svg>
                        </button>
                            <section className="custom-content" ref={el=>{this.custom.el = el}}>


                            </section>
                    </section>

                    <section className="all"  ref={el=>{this.all.el = el}}>
                        <button className="next-btn" onClick={() => {
                            this.all.el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                        }}>
                            Other Work
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-chevron-down">
                                <polyline points="6 9 12 15 18 9"/>
                            </svg>
                        </button>
                        <div className="contents" >

                        </div>
                    </section>
                </div>
            </section>
        )
    }

    animateIntro() {
        this.occupations = db.getCategoryList();
        this.rotator.setItems(this.occupations);
        this.rotator.createLoop(1, 2000);
    }

    orientationChanged(orientation) {
        if (this.mounted) {
            this.setState({
                orientation: orientation,
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.mounted = true;
        db.onReady(() => {
            this.featured.update();
            this.all.update();
            this.custom.update();
        })
    }

    componentWillUnmount() {
        this.rotator.stopLoop();
        win.removeListener(this.orientationChanged);
        loadHandler.loadComplete = null;
    }

}

MainPage.propTypes = {
    onPageChange: PropTypes.func
};
export default MainPage;