import React, {Component} from 'react';
import PropTypes from "prop-types";

class ComboButtons extends Component {
    constructor(props) {
        super(props);

        this.CREATE_BTN = '<$ADD-BTN$>';
        this.CREATE_BTN_INPUT = '<$ADD-BTN-INPUT$>';
        // TODO: ADD SUPPORT FOR INLINE CREATE

        this.items = {};
        this.selected = [];
        this.main = null;

        this.click = this.click.bind(this);
        this.addButton = this.addButton.bind(this);
        this.addCreateButton = this.addCreateButton.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.reset = this.reset.bind(this);
        this.onCreateCalled = this.onCreateCalled.bind(this);

    }

    render() {
        return(
            <div className="horizontal-combo-select" ref={el=>{this.main=el}}>

            </div>
        )
    }

    addButton(name, id) {
        let item = document.createElement('button');
        item.className = 'combo-item';
        item.id = id;
        item.onclick = this.click;
        item.innerText = name;
        this.items[id.toString()] = item;
        this.main.appendChild(item);
    }

    addCreateButton(name) {
        this.addButton(name, this.CREATE_BTN);
        // this.items[this.CREATE_BTN].style.background = '#623643';
    }

    click(evt) {
        if (evt.target.id === this.CREATE_BTN) {
            this.onCreateCalled(this.items[this.CREATE_BTN]);
        } else if(evt.target.id === this.CREATE_BTN_INPUT) {
            // pass
        } else {
            this.setSelected(evt.target.id);
            this.props.onSelected(this.selected);
        }
    }

    setSelected(value) {
        if (this.props.exclusive) {
            Object.keys(this.items).forEach((key) => {
                let item = this.items[key];
                item.classList.remove('selected');
            });
            this.selected.length = 0;
            this.items[value].classList.add('selected');
            this.props.onSelectEffect(this.items[value]);
            this.selected.push(value);
        } else {
            let ind = this.selected.indexOf(value);
            if (ind < 0) {
                this.selected.push(value);
                this.items[value].classList.add('selected');
                this.props.onSelectEffect(this.items[value]);
            } else {
                this.selected.splice(ind, 1);
                this.items[value].classList.remove('selected');
            }
        }

    }

    reset() {
        this.main.innerHTML = '';
    }

    onCreateCalled(item) {
        item.style.display = 'inline-flex';
        let addInput = document.createElement('input');
        addInput.setAttribute('type', 'text');
        addInput.id = this.CREATE_BTN_INPUT;
        item.setAttribute('data-prev', item.innerText);
        addInput.onkeyup = (evt) => {
            switch(evt.code) {
                case 'Escape':
                    item.innerHTML = item.getAttribute('data-prev');
                    break;
                case 'Enter':
                    // save
                    this.onSave(addInput.value);
                    item.innerHTML = item.getAttribute('data-prev');
                    break;
                case 'Space':
                    evt.preventDefault();
                    break;
                default:
                    // Update Text;
                    break;
            }
        };

        let saveBtn = document.createElement('button');
        saveBtn.className = 'save-btn';
        saveBtn.id = this.CREATE_BTN_INPUT;
        saveBtn.innerText = '✔';
        saveBtn.onclick = () => {
            this.props.onSave(addInput.value);
            item.innerHTML = item.getAttribute('data-prev');
        };

        item.innerHTML = '';
        item.appendChild(addInput);
        item.appendChild(saveBtn);
        addInput.focus();
    }

}

ComboButtons.propTypes = {
    exclusive: PropTypes.bool,
    onSelected: PropTypes.func,
    onSave: PropTypes.func,
    onSelectEffect: PropTypes.func,
};

export default ComboButtons;