import React, {Component} from 'react';
import Utils from '../tools/utils.js';
import {loadHandler} from '../tools/loader.js';
import PropTypes from "prop-types";

class Page extends Component {
    constructor(props) {
        super(props);
        this._uuid = Utils.generateUID(7);
        this.changePage = this.changePage.bind(this);
        loadHandler.registerToLoad(this._uuid);

        this.state = {

        };
    }

    render() {
        return('');
    }

    componentDidMount() {
        loadHandler.loaded(this._uuid);
    }

    static renderImage(src, alt) {
        let uuid = loadHandler.registerLoad();
        return(
            <img key={uuid} id={uuid} alt={alt} src={src} onLoad={() => {loadHandler.loaded(uuid)}}/>
        )
    }

    changePage(urlParams, page) {
        let params = new URLSearchParams();
        params.set(urlParams[0], urlParams[1]);
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
        window.history.pushState({path:newUrl},'',newUrl);
        this.props.onPageChange(page);
    }

}

Page.propTypes = {
    onPageChange: PropTypes.func
};


export default Page;