import React from 'react';
import db, {storage} from '../database.js';
import Page from './page.js';
import auth from '../tools/auth.js';
import PropTypes from "prop-types";
import Header from "../tools/header";
import Modal from "../tools/modal";

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import ReactQuill from 'react-quill';
import ComboButtons from "../tools/combo-buttons";
import {loadHandler} from "../tools/loader";
import ImgResize from "../tools/img-tools";

// TINY-MCE API KEY:
// 3gpxvnbal3w8dph370lacg5yd3xehvgofe6o5h7ld4qsce5s

class EditorPage extends Page {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            text: "",
            featuredImg: "",
            order: 0,
            featured: false,
            publish: false,
            category: [],
        };

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let editor = params.get('editor');

        this.modes = [];
        if (editor != null) {
            this.modes = editor.split(".");
        }

        this.logout = this.logout.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);

        this.previousValue = "";
        this.editor = null;
        this.quill = null;
        this.custom = null;
        this.order = null;
        this.title = null;
        this.featured = null;
        this.publish = null;
        this.combo = null;

        this.container = null;

        this.imgSelect = null;
        this.imgUrl = null;
        this.modal = null;
        this.uploadBtn = null;

        this.videoModal = null;
        this.videoTA = null;

        this.codeModal = null;
        this.codeTA = null;

        this.imageModel = {
            selectedUrl: '',
            file: null,
            callback: null,
            tool: null,
            reset: () => {
                this.imageModel.selectedUrl = '';
                this.imageModel.file = null;
                this.imageModel.callback = null;
                this.imgUrl.value = '';
                this.uploadBtn.innerText = 'Upload Image';
            },
            call: (callback) => {
                this.imageModel.reset();
                this.imageModel.callback = callback;
                this.modal.show();

                this.imageModel.updateSelect()
            },
            updateSelect: () => {
                db.getImageURLs().then(urls => {
                    this.imgSelect.innerHTML = '';
                    urls.forEach(url => {
                        let img = document.createElement('img');
                        img.className = "item";
                        img.setAttribute('src', url);
                        img.onclick = () => {
                            this.imageModel.selectedUrl = url;
                            this.imageModel.callback(this.imageModel.selectedUrl);
                            this.modal.hide();
                        };
                        this.imgSelect.appendChild(img);
                    })
                })
            },
            insertImage: () => {
                console.log(this.imageModel);
                this.imageModel.callback(this.imgUrl.value);
                this.modal.hide();
            },
            uploadImage: (evt) => {
                console.log(this.imageModel);
                if (this.imageModel.file != null) {
                    storage.uploadImage(this.imageModel.file).then(path => {
                        this.imageModel.callback(path);
                        this.modal.hide();
                    });
                    evt.target.innerText = 'Uploading...';
                } else {
                    evt.target.innerText = 'No File Selected';
                    setTimeout(() => {
                        this.uploadBtn.innerText = "Upload Image";
                    }, 1000);
                }
            },
            setImageResize: () => {
                document.querySelectorAll(".ql-container img").forEach(el=>{
                    el.onclick = this.imageModel.onImgClick;
                });
                document.querySelectorAll("iframe.ql-video").forEach(el=>{
                    el.onmouseover = this.imageModel.onImgClick;
                    el.onmouseout = this.imageModel.tool.tools.reset();
                });
            },
            onImgClick: (evt) => {
                // console.log(evt.target.getBoundingClientRect());
                this.imageModel.tool.tools.updateImg(evt.target);
            }
        }
    }

    modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color',
        'align',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
    ];



    render() {
        return(
            <section className="editor container-wrapper">
                <Header onPageChange={this.props.onPageChange} params={["admin-console", ""]}
                        fullWidth={true} page="admin-console">
                    <div className="title">
                        EDITOR
                    </div>
                    <button className="btn" onClick={this.logout}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor"
                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-log-out">
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                            <polyline points="16 17 21 12 16 7"/>
                            <line x1="21" y1="12" x2="9" y2="12"/>
                        </svg>
                    </button>
                </Header>
                <div className="container-none" ref={el=>this.container=el}>
                    <div className="box">
                        <ImgResize ref={el=>{this.imageModel.tool = el}}/>
                        <div className="absolute-box">
                            <div className="custom-content" ref={(el) => {this.custom = el}}
                                 onClick={() => {
                                     this.imageModel.call((path) => {
                                         this.custom.innerHTML = '<img src="' + path + '"/>';
                                         this.state.featuredImg = path;
                                     })
                                 }}>
                                {Page.renderImage(require('../images/upload-img.png'))}
                            </div>
                            <div className="title">
                                <input type="text" ref={el => {this.title = el}} className="title-input" placeholder="Title Here" onChange={
                                    (evt) => {
                                        this.state.title = evt.target.value;
                                    }
                                }/>
                            </div>
                        </div>
                        <section className="view-box">
                            <section className="inner-view">
                                <ReactQuill ref={(el) => {this.quill = el}}
                                            value={this.state.text}
                                            modules={this.modules}
                                            formats={this.formats}
                                            onChange={this.onChange}/>

                                <section className="additionalOptions">
                                    <button className="btn additional" style={{background: "#333"}}
                                            onClick={() => {
                                                this.codeTA.value = this.editor.root.innerHTML;
                                                this.codeModal.show();
                                            }}>
                                         &lt; code &gt;
                                    </button>
                                    <div>Order
                                        <input id="order" type="number" ref={(el) => {this.order = el}}/>
                                    </div>
                                    <div>Featured
                                        <input id="featured" type="checkbox" onChange={(evt) => {
                                            this.state.featured = evt.target.checked;
                                            console.log(this.state.featured);
                                        }} ref={el => {this.featured = el}}/>
                                    </div>
                                    <div>Publish
                                        <input id="publish" type="checkbox" onChange={(evt) => {
                                            this.state.publish = evt.target.checked;
                                            console.log(this.state.publish);
                                        }} ref={el => {this.publish = el}}/>
                                    </div>
                                    <ComboButtons onSelected={sel => {this.state.category = sel}}
                                                  exclusive={false} ref={el=> {this.combo = el}}
                                                  onSelectEffect={item => {
                                                      item.style.background = loadHandler.invertFill;
                                                      item.style.color = "#fff";
                                                  }}/>
                                </section>

                                <div className="buttons" >
                                    <button id="delete" className="btn" onClick={this.delete}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round" className="feather feather-trash-2">
                                            <polyline points="3 6 5 6 21 6"/>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                            <line x1="10" y1="11" x2="10" y2="17"/>
                                            <line x1="14" y1="11" x2="14" y2="17"/>
                                        </svg>
                                    </button>
                                    <button id="save" className="btn" onClick={this.save}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round" className="feather feather-save">
                                            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/>
                                            <polyline points="17 21 17 13 7 13 7 21"/>
                                            <polyline points="7 3 7 8 15 8"/>
                                        </svg>
                                    </button>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
                <Modal ref={(el) => this.modal = el}>
                    <div className="url-modal">
                        <input type="text" id="url" ref={(el) => {this.imgUrl = el}} placeholder="Image URL: http://"/>
                        <button onClick={this.imageModel.insertImage}>Insert Image</button>
                    </div>
                    <div className="select-modal">
                        <div className="data-modal" ref={(el) => {this.imgSelect = el}}>

                        </div>
                    </div>
                    <div className="upload-modal">
                        <input type="file" onChange={(evt) => {
                            console.log(evt.target.files);
                            let file = evt.target.files[0];

                            if (/^image\//.test(file.type)) {
                                this.imageModel.file = file;
                            }

                            evt.target.file = null;
                        }}/>
                        <button ref={el => {this.uploadBtn = el}}
                            onClick={this.imageModel.uploadImage}>Upload Image</button>
                    </div>
                </Modal>
                <Modal ref={(el) => this.videoModal = el}>
                    <textarea rows="10" cols="80" ref={el=>this.videoTA=el}>

                    </textarea>
                    <button onClick={()=>{
                        let position = this.editor.getSelection(true);
                        // let data_html = this.editor.root.innerHTML;
                        this.editor.clipboard.dangerouslyPasteHTML(position.index,  this.videoTA.value);
                        this.imageModel.setImageResize();
                        // this.editor.root.innerHTML = data_html.slice(0, position.index + 1) + this.videoTA.value + data_html.slice(position.index + 1, data_html.length);
                        this.videoModal.hide();
                    }}>Embed</button>
                </Modal>
                <Modal ref={(el) => this.codeModal = el}>
                    <textarea rows="20" cols="80" ref={el=>this.codeTA=el}>
                    </textarea>
                    <button onClick={()=>{
                        this.editor.root.innerHTML = this.codeTA.value;
                        // this.editor.root.innerHTML = data_html.slice(0, position.index + 1) + this.videoTA.value + data_html.slice(position.index + 1, data_html.length);
                        this.codeModal.hide();
                    }}>Embed</button>
                </Modal>
            </section>
        )
    }

    onChange(val, delta, src, editor) {
        this.state.text = val;
    }

    save(evt) {
        let order = parseInt(this.order.value);
        let object = {
            title: this.state.title,
            publish: this.state.publish,
            featured: this.state.featured,
            featimg: this.state.featuredImg,
            order: isNaN(order) ? 0 : order,
            desc: this.state.text,
            category: this.state.category,
        };

        console.log(object);

        if (this.modes[0] === 'edit') {
            db.updatePost(this.modes[1], object);

        } else if (this.modes[0] === 'add') {
            db.addPost(object);
            // this.changePage(['editor', 'edit.' + key], 'editor');
        }

        this.changePage(['admin-console', ''], 'admin-console');

    }

    delete() {

        if (window.confirm("Are you sure you want to delete this post?")) {
            db.removePost(this.modes[1]);
            this.changePage(['admin-console', ''], 'admin-console');
        } else {

        }

    }

    logout() {
        auth.logout(() => {
            let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            window.history.pushState({path:newUrl},'',newUrl);
            this.props.onPageChange('main');
        })
    }

    componentDidMount() {
        super.componentDidMount();
        //
        // auth.isAdmin().then(isAdmin => {
        //     if (!isAdmin) {
        //         this.logout();
        //     }
        // });

        this.editor = this.quill.getEditor();
        this.quill.getEditor().getModule("toolbar").addHandler("image", () => {
            this.imageModel.call((path) => {
                let range = this.editor.getSelection(true);
                this.editor.insertEmbed(range.index, 'image', path);
                this.imageModel.setImageResize();
            })
        });

        this.quill.getEditor().getModule('toolbar').addHandler('video', ()=>{
            //https://drive.google.com/file/d/1ERmgzR-2ScGFy2oGO-RWSF0Wjp1ukd3R/view?usp=sharing
            this.videoModal.show();
        });

        db.onReady(() => {
            if (this.modes[0] === 'edit') {
                let post = db.postDB[this.modes[1]];
                this.state.title = post.title;
                this.state.text = post.desc;
                this.state.featuredImg = post.featimg;
                this.state.order = post.order;
                this.state.featured = post.featured;
                this.state.publish = post.publish;

                if (post.featimg !== '') {
                    this.custom.innerHTML = '<img src="' + post.featimg + '"/>';
                }
                this.order.value = post.order;
                this.editor.root.innerHTML = post.desc;
                this.title.value = post.title;
                this.featured.checked = post.featured;
                this.publish.checked = post.publish;

                let catKeys = Object.keys(db.catDB);
                catKeys.forEach(key => {
                    let cat = db.catDB[key];
                    this.combo.addButton(cat.title, key);
                });

                if (post.category !== undefined) {
                    this.state.category = post.category;
                    post.category.forEach(key => {
                        if (catKeys.indexOf(key) >= 0) {
                            this.combo.setSelected(key);
                        }
                    })
                }

                this.imageModel.setImageResize();
            }
        });


    }
}

EditorPage.propTypes = {
    onPageChange: PropTypes.func
};

export default EditorPage;