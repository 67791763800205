class Utils {
    static generateUID(n) {
        let txt = Math.random().toString(36).replace('0.', '') + Math.random().toString(36).replace('0.', '') + Math.random().toString(36).replace('0.', '');
        return txt.substring(0, n);
    }

    static invertColor(hex) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + Utils.padZero(r) + Utils.padZero(g) + Utils.padZero(b);
    }

    static padZero(str, len) {
        len = len || 2;
        let zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }
}

let win = {
    html: document.querySelector('html'),
    body: document.querySelector('body'),
    orientation: 100,
    orientationListeners: [],
    PORTRAIT:102,
    LANDSCAPE:101,
    setOrientationListener: (listener) => {
        if (win.orientationListeners.indexOf(listener) < 0) {
            win.orientationListeners.push(listener);
        }
    },
    removeListener: (listener) => {
        let ind = win.orientationListeners.indexOf(listener);
        if (ind >= 0) {
            win.orientationListeners.splice(ind, 1);
        }
    }
};

function checkOrientation() {
    if (window.innerWidth > window.innerHeight) {
        win.orientation = win.LANDSCAPE;
    } else {
        win.orientation = win.PORTRAIT;
    }

    win.orientationListeners.forEach(listener => {
        listener(win.orientation);
    })
}

win.body.onresize = checkOrientation;

checkOrientation();

export {win};
export default Utils;