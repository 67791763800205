import React, {Component} from 'react';
import './scss/main.scss';
import Loader from './tools/loader.js';
import PageRouter from './tools/PageRouter.js';


class App extends Component{
    constructor() {
        super();
        this.state = {
            progress: 0,
        };
        this.fill = "#fff";

    }
    render() {
        return (
            <div className="App">
                <Loader
                    ref="loader" onFill={(fill) => {this.fill = fill}}/>
                <PageRouter />
            </div>
        );
    }
}

export default App;
