import React from 'react';
import db from '../database.js';
import Page from './page.js';
import auth from '../tools/auth.js';
import PropTypes from "prop-types";
import Header from "../tools/header";
import {loadHandler} from "../tools/loader";
import Modal from "../tools/modal";


class AdminPage extends Page {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.logout = this.logout.bind(this);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.updateList = this.updateList.bind(this);

        this.outer = React.createRef();
        this.inner = React.createRef();
        this.cat = {
            key: null,
            title: {
                el: null,
            },
            order: {
                el: null,
            },
            save: () => {
                let n = parseInt(this.cat.order.el.value);
                let obj = {
                    title: this.cat.title.el.value,
                    order: isNaN(n) ? 0 : n,
                };
                if (this.cat.key == null) {
                    db.createCategory(obj);
                } else {
                    db.updateCategory(this.cat.key, obj);
                }

                this.modal.hide();
                this.updateList();
            },
            reset: () => {
                this.cat.key = null;
                this.cat.title.el.value = "";
                this.cat.order.el.value = 0;
            },
            edit: (uuid) => {
                this.cat.reset();
                this.cat.key = uuid;
                let cat = db.catDB[uuid];
                this.cat.title.el.value = cat.title;
                this.cat.order.el.value = cat.order;
                this.modal.show();
            },
            show: () => {
                this.cat.reset();
                this.modal.show();
            },
            delete: () => {
                if (this.cat.key != null) {
                    // remove from all posts
                    // remove the item
                    if (window.confirm("Are you sure you want to delete this Category?")) {
                        db.removeCategory(this.cat.key);
                        this.modal.hide();
                        this.updateList();
                    }
                }
            }
        };
        this.catInner = null;
        this.modal = null;

        this.codeModal = null;
        this.codeTA = null;
        this.codeOrder = null;
        this.codeVisible = null;

        this.custom = {
            el: null,
            editing: null,
            update: () => {
                this.custom.el.innerHTML = '';
                let sections = db.getAllCustomContent();
                Object.keys(sections).forEach(key=>{
                    let bar = document.createElement('button');
                    bar.className = "custom-bar-edit btn";
                    bar.setAttribute("data-id", key);
                    bar.innerHTML = "Edit";
                    bar.onclick = () => {this.custom.edit(key)};
                    this.custom.el.appendChild(bar);

                    let item = document.createElement('div');
                    item.id = key;
                    item.innerHTML = sections[key].desc;
                    this.custom.el.appendChild(item);
                });
            },
            edit: (uuid) => {
                this.custom.editing=uuid;
                let item = db.getCustomContent(uuid);

                this.codeTA.value = item.desc;
                this.codeOrder.value = item.order;
                this.codeVisible.checked = item.visible;
                this.codeModal.show();

            },
            add: () => {
                this.custom.editing=null;

                this.codeTA.value = '';
                this.codeOrder.value = 0;
                this.codeVisible.checked = false;
                this.codeModal.show();
            }
        };
    }

    render() {
        return(
            <section className="admin container-wrapper">
                <Header onPageChange={this.props.onPageChange}
                        fullWidth={true} params={["admin-console", ""]} page="admin-console">
                    <div className="title">ADMIN</div>
                    <button className="btn" onClick={this.logout}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor"
                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-log-out">
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                            <polyline points="16 17 21 12 16 7"/>
                            <line x1="21" y1="12" x2="9" y2="12"/>
                        </svg>
                    </button>
                </Header>

                <div className="container-none">
                    <div className="box">
                        <section className="view-box" ref={this.outer}>
                            <section className="admin-section">
                                <div className="title"> Posts </div>
                                <button className="btn" style={{"padding": 0, "margin": 0}} onClick={() => {this.add("portfolio")}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke={loadHandler.fill} strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-plus-square">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
                                        <line x1="12" y1="8" x2="12" y2="16"/>
                                        <line x1="8" y1="12" x2="16" y2="12"/>
                                    </svg>
                                </button>
                            </section>
                            <section className="inner-view portfolio-list" ref={this.inner}>
                            </section>
                        </section>
                        <section className="view-box">
                            <section className="admin-section">
                                <div className="title"> CATEGORIES </div>
                                <button className="btn" style={{"padding": 0, "margin": 0}} onClick={this.cat.show}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke={loadHandler.fill} strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-plus-square">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
                                        <line x1="12" y1="8" x2="12" y2="16"/>
                                        <line x1="8" y1="12" x2="16" y2="12"/>
                                    </svg>
                                </button>
                            </section>
                            <section className="inner-view category-list" ref={el=>{this.catInner=el}}>
                            </section>
                        </section>
                        <button className="custom-bar-edit btn" onClick={() => {
                            this.custom.add();
                        }}>
                            Add New Custom Section
                        </button>
                        <section className="custom-content" ref={el=>this.custom.el = el}>

                        </section>
                    </div>
                </div>
                <Modal ref={(el) => this.modal = el}>
                    <div className="url-modal">
                        <input type="text" id="title" ref={(el) => {this.cat.title.el = el}}
                               placeholder="Category Title"/>
                        <input type="number" id="order" ref={(el) => {this.cat.order.el = el}}
                               placeholder="Order" />
                        <button onClick={this.cat.save} style={{"marginRight": "1rem"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-save">
                                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/>
                                <polyline points="17 21 17 13 7 13 7 21"/>
                                <polyline points="7 3 7 8 15 8"/>
                            </svg>
                        </button>
                        <button onClick={this.cat.delete}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-trash-2">
                                <polyline points="3 6 5 6 21 6"/>
                                <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                <line x1="10" y1="11" x2="10" y2="17"/>
                                <line x1="14" y1="11" x2="14" y2="17"/>
                            </svg>
                        </button>
                    </div>
                </Modal>
                <Modal ref={(el) => this.codeModal = el}>
                    <textarea rows="20" cols="80" ref={el=>this.codeTA=el}>
                    </textarea>
                    <span> Order </span>
                    <input type="number" ref={el=>this.codeOrder=el}/>
                    <span> Visible </span>
                    <input type="checkbox" ref={el=>this.codeVisible=el}/>
                    <button onClick={()=>{
                        let obj = {
                            desc: this.codeTA.value,
                            order: parseInt(this.codeOrder.value),
                            visible: this.codeVisible.checked,
                        };

                        console.log(obj);
                        if (this.custom.editing != null) {
                            db.updateCustomContent(this.custom.editing, obj);
                        } else {
                            db.addCustomContent(obj);
                        }
                        this.codeModal.hide();
                        this.custom.update();
                    }}>Save</button>

                    <button onClick={() => {
                        if (window.confirm("Are you sure you want to delete this custom content?")) {
                            db.removeCustomContent(this.custom.editing);
                        }
                        this.codeModal.hide();
                        this.custom.update();
                    }}>Remove</button>
                </Modal>
            </section>
        )
    }

    updateList() {
        this.inner.current.innerHTML  = '';
        this.catInner.innerText = '';
        Object.keys(db.postDB).forEach((key) => {
            let post = db.postDB[key];
            let item = document.createElement('div');
            item.className = 'item';
            item.innerHTML = '<div class="item-title">' + (post.title === '' ? '...' :  post.title) + '</div> ';
            if (post.publish) {
                item.innerHTML += '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="'+ loadHandler.fill +'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>';
            }
            if (post.featured) {
                item.innerHTML += '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="' + loadHandler.fill + '" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>';
            }
            item.onclick = () => {
                this.edit(key);
            };
            this.inner.current.appendChild(item);
        });
        Object.keys(db.catDB).forEach(key => {
            let cat = db.catDB[key];
            let item = document.createElement('div');
            item.className = 'item';
            item.innerHTML = '<div class="item-title">' + cat.title + '</div> ';
            item.onclick = () => {
                this.cat.edit(key);
            };
            this.catInner.appendChild(item);
        })
    }

    edit(uuid) {
        let params = new URLSearchParams();
        params.set('editor', 'edit.' + uuid);
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
        window.history.pushState({path:newUrl},'',newUrl);
        this.props.onPageChange('editor');
    }

    add(type) {
        let params = new URLSearchParams();
        params.set('editor', 'add.' + type);
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
        window.history.pushState({path:newUrl},'',newUrl);
        this.props.onPageChange('editor');
    }



    logout() {
        auth.logout(() => {
            this.changePage(['', ''], 'main');
        })
    }

    componentDidMount() {
        super.componentDidMount();

        db.onReady(() => {
            this.updateList();
            this.custom.update();
        });
        //
        // auth.isAdmin().then(isAdmin => {
        //     if (!isAdmin) {
        //        this.logout();
        //     }
        // })
    }
}

AdminPage.propTypes = {
    onPageChange: PropTypes.func
};

export default AdminPage;