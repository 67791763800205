import React from 'react';
import Page from './page.js';
import {loadHandler} from "../tools/loader";
import auth from '../tools/auth.js';

import PropTypes from 'prop-types';
import Header from "../tools/header";

class AdminLoginPage extends Page {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.login = this.login.bind(this);
    }

    render() {
        let buttonStyle = {
            color: loadHandler.fill,
        };
        return(
            <section className="admin-login container-wrapper">
                <Header onPageChange={this.props.onPageChange}
                        fullWidth={true} params={["", ""]} page="main"/>
                <div className="container-none">
                    <div className="login-panel">
                        <button style={buttonStyle} onClick={this.login}>
                            Admin Login
                        </button>
                    </div>
                </div>
            </section>
        )
    }

    login() {
        auth.login(
            (result) => {
                if (result) {
                    let params = new URLSearchParams();
                    params.set('admin-console', '');
                    let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
                    window.history.pushState({path:newUrl},'',newUrl);
                    this.props.onPageChange('admin-console');
                } else {
                    this.props.onPageChange('main');
                }
            }
        )
    }
}

AdminLoginPage.propTypes = {
    onPageChange: PropTypes.func
};

export default AdminLoginPage;