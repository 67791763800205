import React, {Component} from 'react';
import {loadHandler} from '../tools/loader.js';
import anime from 'animejs';

class Modal extends Component {
    constructor(props) {
        super(props);

        this.modal = null;
        this.wrapper = null;

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    render() {
        return(
            <section className="img-modal"
                     ref={(el) => {this.modal = el}}
                     style={{"background": loadHandler.fill}}>
                <button style={{"position": "absolute", "top": "1rem", "right": "1rem"}} onClick={this.hide}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"/>
                        <line x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </button>
                <div className="wrapper"
                     ref={(el) => {this.wrapper = el}}>

                    {this.props.children}
                </div>
            </section>
        )
    }

    hide() {
        anime.timeline().add({
            targets: this.wrapper,
            opacity: 0,
        }).add({
            targets: this.modal,
            translateY: 100 + '%',
            duration: 300,
            easing: 'linear',
        }, "-=600")
    }

    show() {
        anime.timeline().add({
            targets: this.modal,
            translateY: 0,
            duration: 300,
            easing: 'linear',
        }).add({
            targets: this.wrapper,
            opacity: 1,
        }, '+=200')
    }
}

export default Modal;