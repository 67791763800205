import firebase from "firebase";
import db from '../database.js';


const provider = new firebase.auth.GoogleAuthProvider();
const fireauth = firebase.auth();

class Auth {
    constructor() {
        this.user = null;
        fireauth.onAuthStateChanged((user) => {
            if (user != null) {
                this.user = user;
                db.hasAdminAccess(user).then((success) => {
                    if (!success) {
                        this.logout(() => {});
                    }
                })
            }
        })
    }

    isLoggedIn() {
        return this.user !== null;
    }

    isAdmin() {
        return new Promise((resolve) => {
            if (this.isLoggedIn()) {
                db.hasAdminAccess(this.user).then(success => {
                    resolve(success);
                })
            } else {
                resolve(false);
            }
        })
    }

    login(callback) {
        fireauth.signInWithPopup(provider).then(
            (result) => {
                this.user = result.user;
                if (result.user != null) {
                    db.hasAdminAccess(result.user).then((success) => {
                        callback(success);
                    })
                } else {
                    callback(false);
                }

            }
        )
    }

    logout(callback) {
        fireauth.signOut().then(() => {
            this.user = null;
            callback();
        })
    }
}

let auth = new Auth();
export default auth;
