import fire from './fire';
import Utils from "./tools/utils";
import {loadHandler} from "./tools/loader";

class Database {
    constructor() {
        this.db = fire.database();
        this.hasAdminAccess = this.hasAdminAccess.bind(this);

        this.callbacks = {};
        this.postDB = {};
        this.catDB = {};
        this.customDB = {};
        this.ready = false;
        this._uuid = [];
        this.loaded = 0;
        this.readyCallback = () => {};

        this.posts = this.db.ref('posts');
        this.cats = this.db.ref('categories');
        this.custom = this.db.ref('custom');

        this._uuid.push(loadHandler.registerLoad());
        this.posts.on('value', snapshot => {
            if (snapshot.val() !== undefined && snapshot.val() !== null) {
                this.postDB = snapshot.val();
            }
            loadHandler.loaded(this._uuid[0]);
            this.loaded += 1;
            this.readyCheck();
        });
        this._uuid.push(loadHandler.registerLoad());
        this.cats.on('value', snapshot => {
            if (snapshot.val() !== undefined && snapshot.val() !== null) {
                this.catDB = snapshot.val();
            }
            loadHandler.loaded(this._uuid[1]);
            this.loaded += 1;
            this.readyCheck();
        });
        this._uuid.push(loadHandler.registerLoad());
        this.custom.on('value', snapshot => {
            if (snapshot.val() !== undefined && snapshot.val() !== null) {
                this.customDB = snapshot.val();
                // console.log(this.customDB)
            }
            loadHandler.loaded(this._uuid[2]);
            this.loaded += 1;
            this.readyCheck();
        })
    }

    readyCheck() {
        if (!this.ready) {
            if (this.loaded >= this._uuid.length) {
                this.ready = true;
                this.readyCallback();
            }
        }
    }

    onReady(callback) {
        if (this.ready) {
            callback();
        } else {
            this.readyCallback = callback;
        }
    }

    hasAdminAccess(user) {
        return new Promise((resolve) => {
            let writeRef = this.db.ref('admins').child(user.uid).child('write');
            writeRef.once('value').then(snapshot => {
                resolve(snapshot.val() === true);
            })

        })
    }

    addImageURL(url) {
        this.db.ref('images').push(url);
    }

    getImageURLs() {
        return new Promise(resolve => {
            this.db.ref('images').once('value').then(snapshot => {
                let urls = [];
                if (snapshot.val() !== undefined && snapshot.val() !== null) {
                    let obj = snapshot.val();
                    Object.keys(obj).forEach(key => {
                        urls.push(obj[key]);
                    });
                }

                resolve(urls);
            })
        })
    }

    getCategoryList() {
        let pre = [];
        Object.keys(this.catDB).forEach(key => {
            pre.push(this.catDB[key]);
        });
        pre.sort((a, b) => {
            return a.order - b.order;
        });
        let ret = [];
        pre.forEach(obj => {
            ret.push(obj.title);
        });
        return ret;
    }

    createCategory(obj) {
        let key = this.cats.push().key;
        this.cats.child(key).set(obj).then(success=>{console.log("ADDED CAT:", key)});
        return key;
    }

    updateCategory(key, obj) {
        this.cats.child(key).set(obj).then(success=>{console.log("UPDATED CAT:", key)});
    }

    removeCategory(key) {
        this.cats.child(key).remove().then(success=>{console.log("REMOVED:", key)});
    }

    addPost(obj) {
        let key = this.posts.push().key;
        this.posts.child(key).set(obj).then(success=>{console.log("ADDED:", key)});
        return key;
    }

    updatePost(key, obj) {
        this.db.ref('posts').child(key).set(obj).then(success=>{console.log("UPDATED:", key)});
    }

    removePost(key) {
        this.posts.child(key).remove().then(success=>{console.log("REMOVED:", key)});
    }

    getFeaturedPosts() {
        let pre = [];
        Object.keys(this.postDB).forEach(key=>{
            if (this.postDB[key].featured) {
                if (this.postDB[key].publish) {
                    pre.push({
                        key: key,
                        data: this.postDB[key]
                    });
                }
            }
        });

        pre.sort((a, b) => {
            return a.data.order - b.data.order;
        });
        let ret = {};
        pre.forEach(obj => {
            ret[obj.key] = obj.data;
        });
        return ret;
    }

    getNonFeaturedPosts() {
        let pre = [];
        Object.keys(this.postDB).forEach(key=>{
            if (!this.postDB[key].featured) {
                if (this.postDB[key].publish) {
                    pre.push({
                        key: key,
                        data: this.postDB[key]
                    });
                }
            }
        });

        pre.sort((a, b) => {
            return a.data.order - b.data.order;
        });
        let ret = {};
        pre.forEach(obj => {
            ret[obj.key] = obj.data;
        });
        return ret;
    }

    getAllPublished() {
        let ret = {
            featured: {},
            notFeatured: {}
        };
        Object.keys(this.postDB).forEach(key=>{
            if (this.postDB[key].publish) {
                if (this.postDB[key].featured) {
                    ret.featured[key] = this.postDB[key];
                } else {
                    ret.notFeatured[key] = this.postDB[key];
                }
            }
        });
        return ret;
    }

    // Get Custom Content
    getVisibleCustomContent() {
        let ret = {};

        let temp = [];
        Object.keys(this.customDB).forEach(key=>{
            if (this.customDB[key].visible) {
                temp.push({
                    key: key,
                    data: this.customDB[key]
                });
            }
        });

        temp.sort((a, b)=>{
            return a.data.order - b.data.order;
        });

        temp.forEach(obj=>{
            ret[obj.key] = obj.data;
        });

        return ret;
    }

    getAllCustomContent() {
        let ret = {
        };

        let temp = [];
        Object.keys(this.customDB).forEach(key=>{
            temp.push({
                key: key,
                data: this.customDB[key]
            });
        });

        temp.sort((a, b)=>{
            return a.data.order - b.data.order;
        });

        temp.forEach(obj=>{
            ret[obj.key] = obj.data;
        });
        return ret;
    }

    getCustomContent(uuid) {
        return this.customDB[uuid];
    }

    addCustomContent(obj) {
        let key = this.custom.push().key;
        this.custom.child(key).set(obj).then(success=>{console.log("ADDED:", key)});
        return key;
    }

    updateCustomContent(uuid, obj) {
        this.custom.child(uuid).set(obj).then(success=>{console.log("UPDATED:", uuid)});
    }

    removeCustomContent(uuid) {
        this.custom.child(uuid).remove().then(success=>{console.log("REMOVED:", uuid)});
    }

}

class Storage {
    constructor() {
        this.storage = fire.storage();
        this.ref = this.storage.ref();
        this.images = this.ref.child('images');
    }

    uploadImageBase64(base) {
        return new Promise(resolve => {
            this.images.child(Utils.generateUID(16)).putString(base, 'data_url').then((snapshot) => {
                console.log(snapshot.ref.fullPath);
                resolve(snapshot.ref.fullPath);
            })
        })
    }

    uploadImage(file) {
        return new Promise(resolve => {
            let metadata = {
                contentType: 'image/jpeg'
            };
            this.images.child(Utils.generateUID(16)).put(file, metadata).then(snapshot => {
                snapshot.ref.getDownloadURL().then(url => {
                    db.addImageURL(url);
                    resolve(url);
                })
            })
        });
    }
}

let storage = new Storage();
let db = new Database();
export {storage};
export default db;