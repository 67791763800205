import React, {Component} from 'react';
import PropTypes from "prop-types";
import {loadHandler} from "../tools/loader";

class ImgResize extends Component {
    constructor(props){
        super(props);

        this.tools = {
            el: null,
            target: null,
            input: null,
            w: null,
            h: null,
            updateImg: (el) => {
                this.tools.el.style.display = 'flex';
                this.tools.target = el;

                let rect = el.getBoundingClientRect();
                let scrl = this.tools.el.parentNode.scrollTop - this.tools.el.parentNode.getBoundingClientRect().y;
                this.tools.el.style.top = rect.y + rect.height +  scrl + 'px';
                this.tools.el.style.left = rect.x + 'px';
            },
            set: () => {
                if (this.tools.w.checked) {
                    this.tools.target.style.width = this.tools.input.value;
                    this.tools.target.setAttribute('width', this.tools.input.value);
                } else if (this.tools.h.checked) {
                    this.tools.target.style.height = this.tools.input.value;
                    this.tools.target.setAttribute('height', this.tools.input.value);
                }
                this.tools.updateImg(this.tools.target);
            },
            preset: (val) => {
                if (this.tools.w.checked) {
                    this.tools.target.style.width = val;
                    this.tools.target.setAttribute('width', val);
                } else if(this.tools.h.checked) {
                    this.tools.target.style.height = val;
                    this.tools.target.setAttribute('height', val);
                }
                this.tools.updateImg(this.tools.target);
            },
            reset: ()=>{
                this.tools.el.style.display = 'none';
                this.tools.target = null;
                this.tools.input.value = '';
            }
        };
    }

    render() {
        return(
            <div className="img-tools" ref={el=>{this.tools.el=el}}>
                <span>
                    <input type="radio" name="hw" value="width" checked ref={el=>this.tools.w=el}/> width
                    <input type="radio" name="hw" value="height" ref={el=>this.tools.h=el}/> height
                </span>
                <span>
                <button onClick={() => {this.tools.preset('25%')}}>25%</button>
                <button onClick={() => {this.tools.preset('50%')}}>50%</button>
                <button onClick={() => {this.tools.preset('75%')}}>75%</button>
                <button onClick={() => {this.tools.preset('100%')}}>100%</button>
                </span>
                <span>
                <input ref={el=>this.tools.input=el} type="text"/> <button onClick={this.tools.set}>SET</button>
                </span>
                <span>
                <button className="no-bg" onClick={this.tools.reset}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"/>
                        <line x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </button>
                </span>
            </div>
        )
    }

}

export default ImgResize;