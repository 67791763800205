import React, {Component} from 'react';
import {loadHandler} from "../tools/loader";
import MainPage from '../pages/main.js';
import AdminPage from '../pages/admin';
import AdminLoginPage from '../pages/admin-login.js';
import EditorPage from '../pages/editor.js';
import View from '../pages/view.js';
import auth from './auth.js';

class PageRouter extends Component {
    constructor() {
        super();

        this.state = {
            render: false,
            currentPage: "main",
        };
        this.pageSwitch = this.pageSwitch.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handlePageParams = this.handlePageParams.bind(this);
        this.main = React.createRef();
    }

    render() {

        console.log(window.location.search);

        return(
            <section className="ip-main" ref={this.main}>
                {this.state.render ? this.pageSwitch() : null}
            </section>
        )
    }

    handlePageParams() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let view = params.get('v');
        let editor = params.get('editor');
        let admin = params.get('admin') === '';
        let adminConsole = params.get('admin-console') === '';

        if (adminConsole) {
            auth.isAdmin().then(admin => {
                if (admin) {
                    this.setState({
                        currentPage: 'admin-console',
                        render: true
                    })
                } else {
                    window.location.search = 'admin';
                }
            })

        } else if (admin) {
            admin = false;
            this.setState({
                currentPage: 'admin',
                render: true
            })
        } else if (editor !== null) {
            editor = null;
            auth.isAdmin().then(admin => {
                if (admin) {
                    this.setState({
                        currentPage: 'editor',
                        render: true
                    })
                } else {
                    window.location.search = 'admin';
                }
            });

        } else if (view !== null) {
            view = null;
            this.setState({
                currentPage: 'view',
                render: true
            });
        } else {
            this.setState({
                currentPage: 'main',
                render: true
            })
        }



    }

    pageSwitch() {
        switch(this.state.currentPage) {
            case "view":
                return <View onPageChange={this.onPageChange} />;
            case "admin":
                return <AdminLoginPage onPageChange={this.onPageChange}/>;
            case "admin-console":
                return <AdminPage onPageChange={this.onPageChange}/>;
            case "editor":
                return <EditorPage onPageChange={this.onPageChange}/>;
            case "main":
            default:
                return <MainPage onPageChange={this.onPageChange}/>;
        }
    }

    onPageChange(pageID) {
        let eff = () => {
            this.setState({
                currentPage: pageID,
                render: false,
            })
        };
        eff.bind(this);
        loadHandler.reset(eff);
    }

    componentDidUpdate() {
        if (!this.state.render) {
            this.handlePageParams();
            // console.log(this.state);
            // this.setState({ render: true })
        } else {
            // console.log(this.state);
            // console.log(this.state);
        }
    }

    componentDidMount() {
        if (!this.state.render) {
            this.handlePageParams();
            // this.setState({ render: true })
        }
    }
}

export default PageRouter;