import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Logo extends Component {
    constructor(props) {
        super(props);
        this.logo = React.createRef();
        this.test = this.test.bind(this);
    }


    render() {
        return(
            <div className={"ip-logo " + this.props.className}>
                <svg id="ip-logo" className="ip-inner" width="100%" height="100%" viewBox="0 0 500 500"
                     preserveAspectRatio="xMidYMin meet" aria-labelledby="logo_title" ref={this.logo}
                >
                    <path id="k" d="M25.4,195.4V28.5h170.7L110.4,112l85.1,83.4H25.4z"/>
                    <path id="a" d="M474.6,195.4h-171L388.7,28L474.6,195.4z"/>
                    <path id="r" d="M25.4,472V304.6h84.5c13.6,0,25.8,1.6,36.4,4.9c10.7,3.2,19.7,7.5,27.1,12.8c7.4,5.3,12.9,11.3,16.7,18.1
			c3.8,6.8,5.6,13.6,5.4,20.4c0,9.5-3.3,18.5-9.9,27.3c-6.4,8.5-22.5,17.9-48.2,28.1l54.7,55.9H25.4z"/>
                    <path id="u" d="M473.8,305.2v83.4c0,12.1-2.1,23.2-6.4,33.4c-4.3,10.2-10.2,19-17.7,26.4c-7.6,7.4-16.5,13.2-26.9,17.4
			c-10.4,4.2-21.7,6.3-34,6.3c-12.3,0-23.6-2.1-34-6.3c-10.4-4.2-19.4-10-26.9-17.4c-7.6-7.4-13.5-16.2-17.7-26.4
			c-4.3-10.2-6.4-21.3-6.4-33.4v-83.4H473.8z"/>
                </svg>
            </div>
        )
    }

    test() {
        console.log("TEST")
    }

    componentDidMount() {
        this.logo.current.querySelectorAll('path').forEach((el) => {
            el.style.fill = this.props.color;
        });

        this.logo.current.parentNode.style.width = this.props.width;
        this.logo.current.style.width = this.props.width;
    }
}

Logo.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default Logo;